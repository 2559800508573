import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { renderBlock } from "../helpers/renderBlock";
import { HelmetBlock } from "../components/HelmetBlock";
import { CookieBanner } from "../components/CookieBanner";

import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/old_style.css";

const HeaderComparador = ({ name }) => (
  <div className="backgroundcointainer">
    <div className="container">
      <div className="logo">
        <span className="go">GO</span>
        <span className="prestamo">préstamo</span>
      </div>
      <div className="containerThreetitle">
        <div className="threetitle">
          ¡<span className="name">{name}</span>, estas son tus ofertas!
        </div>
      </div>
    </div>
  </div>
);

const Comparador = ({ data, location }) => {
  const components = data.wpPage.components.component;
  const pageId = data.wpPage.databaseId;
  const [comparadorName, setComparadorName] = useState("");
  const [comparadorLead, setComparadorLead] = useState("");
  const isDebugMode =
    typeof window !== `undefined` &&
    window.localStorage.getItem("debugMode") === "on";
  // Debug
  useEffect(() => {
    if (isDebugMode) {
      console.debug("Comparador step state:", location.state);
    }
  }, []);

  useEffect(() => {
    setComparadorName(location.state ? location.state.name : "");
    setComparadorLead(location.state ? location.state.lead : "");
  }, []);

  return (
    <>
      <HelmetBlock data={data.wpPage} />
      <HeaderComparador name={comparadorName} />
      <div className="container ComparadorRelative">
        {components &&
          components.map((item, i) => (
            <React.Fragment key={`${item.fieldGroupName}_${i}`}>
              {renderBlock(item, pageId, comparadorName, comparadorLead)}
            </React.Fragment>
          ))}
      </div>
      <CookieBanner />
    </>
  );
};

export default Comparador;

export const pageQuery = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
      databaseId
      parentDatabaseId
      seo {
        title
        metaDesc
      }
      components {
        component {
          ... on WpPage_Components_Component_Main {
            fieldGroupName
            ...MainBlock
          }
          ... on WpPage_Components_Component_HowTo {
            fieldGroupName
            ...HowToBlock
          }
          ... on WpPage_Components_Component_Reviews {
            fieldGroupName
          }
          ... on WpPage_Components_Component_Faq {
            fieldGroupName
            ...FaqBlock
          }
          ... on WpPage_Components_Component_Info {
            fieldGroupName
            ...InfoBlock
          }
          ... on WpPage_Components_Component_Content {
            fieldGroupName
          }
          ... on WpPage_Components_Component_Creditors {
            fieldGroupName
            ...CreditorsBlock
          }
        }
      }
    }
  }
`;
